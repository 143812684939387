<template>
    <div>
        <el-form :inline="true" :model="optionForm" size="mini">
            <el-form-item label="操作说明" prop="opName">
                <el-input v-model="optionForm.opName" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="操作时间" prop="opTime">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="optionForm.opTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getOptionRecordData">查询</el-button>
                <el-button type="primary" @click="downloadOptionRecordData" :loading="exportLoading">下载</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="optionRecordData" border size="small" stripe style="width: 100%"
                  :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column prop="opTime" label="操作时间"/>
            <el-table-column prop="opName" label="操作说明">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.opName}}
                        <el-button v-if="scope.row.opName === '设置参数'" size="mini" type="text" @click="showParamsDetailPage(scope.row.id)">参数详情</el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注">
                <template v-if="scope.row.remark" slot-scope="scope">
                    <span v-if="String(scope.row.remark).length < 20">{{scope.row.remark}}</span>
                    <span v-else>
                        <el-popover
                            placement="right"
                            width="220"
                            trigger="click"
                            :content="scope.row.remark">
                            <span slot="reference">{{String(scope.row.remark).substr(0, 20)}}...</span>
                        </el-popover>
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="操作人" />
        </el-table>
        <div style="margin-top: 15px;text-align: right;">
            <el-pagination background layout="total, prev, pager, next, jumper" :total="total"
                           @current-change="handleCurrentChange" :current-page.sync="curr"/>
        </div>
        <el-dialog title="参数详情信息" :visible="paramsDetailPageVisible" @close="closeParamsDetailPage" center width="1200px" append-to-body :close-on-click-modal="false">
            <el-form :inline="true" :model="paramForm" ref="paramForm" :disabled="true">
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="停止锂电池充电直流母线电压值上限：">
                            <el-input v-model="paramForm.stopBatDcBusVMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="V">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="启动锂电池充电直流母线电压值下限：">
                            <el-input v-model="paramForm.startBatDcBusVMin"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="V">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="停止锂电池充电SOC上限：">
                            <el-input v-model="paramForm.stopBatSocMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="%">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="启动锂电池充电SOC下限：">
                            <el-input v-model="paramForm.startBatSocMin"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="%">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="锂电池预热箱加热控制温度上限：">
                            <el-input v-model="paramForm.batPLBHCTempMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="锂电池预热箱加热控制温度下限：">
                            <el-input v-model="paramForm.batPLBHCTempMix"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="散热轴流风机控制温度上限：">
                            <el-input v-model="paramForm.startTempCafMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="散热轴流风机控制温度下限：" >
                            <el-input v-model="paramForm.stopTempCafMin"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="设备舱温度上限：">
                            <el-input v-model="paramForm.dcTempMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="电控舱温度上限：">
                            <el-input v-model="paramForm.eccTempMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 15px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="锂电池充电限流点：">
                            <el-input v-model="paramForm.batCle"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="C">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="本站负载平均电流：">
                            <el-input v-model="paramForm.loadAvgI"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="A">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="保温策略电控舱温度上限：">
                            <el-input v-model="paramForm.isEccTempMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="保温策略电控舱温度下限：">
                            <el-input v-model="paramForm.isEccTempMin"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="保温策略电芯温度上限：">
                            <el-input v-model="paramForm.isElecTempMax"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="保温策略电芯温度下限：">
                            <el-input v-model="paramForm.isElecTempMin"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="℃">
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 5px">
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="锂电池充满间隔时间：">
                            <el-input v-model="paramForm.batFillIntervalTime"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="天">
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" style="text-align: end">
                        <el-form-item label="柴发例行启动间隔时间：">
                            <el-input v-model="paramForm.engRoutineStartTime"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align: left">
                        <el-form-item label="天">
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {exportDeviceOpRecords, queryOptionRecordList, queryDeviceParamsDetailInfo} from "../../../api/station/deviceInfoApi";

    export default {
        name: "deviceOptionRecord",
        data(){
            return {

                listLoading: false,
                exportLoading: false,
                paramsDetailPageVisible:false,
                optionForm: {

                    wholeId: '',
                    opName: '',
                    opTime: ''
                },
                // 设备操作记录数据
                optionRecordData:[],
                // 设备操作记录总数
                total: 0,
                curr: 1,
                size: 10,
                paramForm: {

                    stopBatDcBusVMax: '',
                    startBatDcBusVMin: '',
                    stopBatSocMax: '',
                    startBatSocMin: '',
                    batPLBHCTempMax: '',
                    batPLBHCTempMix: '',
                    startTempCafMax: '',
                    stopTempCafMin: '',
                    dcTempMax: '',
                    eccTempMax: '',
                    batCle: '',
                    cpsOutputPMin: ''
                },
            };
        },
        methods: {

            // 关闭页面
            closeOpRecordPage() {

                this.$parent.closeOpRecordPage();
            },
            // 获取设备操作记录表格数据
            getOptionRecordData() {

                let time = this.optionForm.opTime;
                let sTime = '';
                let eTime = '';
                if (time != '' && time != null) {

                    sTime = time[0] + ' 00:00:00';
                    eTime = time[1] + ' 23:59:59';
                }
                let params = {

                    size: this.size,
                    curr: this.curr,
                    deviceWholeId: this.optionForm.wholeId,
                    opName: this.optionForm.opName,
                    startTime: sTime,
                    endTime: eTime
                };
                queryOptionRecordList(params).then((res) => {

                    this.total = res.data.totalNum;
                    this.optionRecordData = res.data.listResult;
                }).catch(err=> console.log(err));
            },
            // 查询设备所有的操作记录
            queryOptionRecord(id) {

                this.optionForm.wholeId = id;
                this.optionForm.opName = '';
                this.optionForm.opTime = '';
                this.curr = 1;
                this.getOptionRecordData();
            },
            // 处理操作记录分页
            handleCurrentChange(val) {

                this.curr = val;
                this.getOptionRecordData();
            },
            // 下载操作记录
            downloadOptionRecordData() {

                let time = this.optionForm.opTime;
                let sTime = '';
                let eTime = '';
                if (time != '' && time != null) {

                    sTime = time[0] + ' 00:00:00';
                    eTime = time[1] + ' 23:59:59';
                }
                let params = {

                    deviceWholeId: this.optionForm.wholeId,
                    opName: this.optionForm.opName,
                    startTime: sTime,
                    endTime: eTime
                };
                this.exportLoading = true;
                exportDeviceOpRecords(params).then(res => {

                    this.exportLoading = false;
                    const blob = new Blob([res]);
                    const fileName = "设备操作记录.xlsx";
                    //  创建 a标签
                    const elink = document.createElement('a');
                    elink.download = fileName;
                    // 创建下载链接
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    // 释放掉blob对象
                    URL.revokeObjectURL(elink.href);
                    // 下载完成移除元素
                    document.body.removeChild(elink);
                })
            },
            // 显示参数详情页面
            showParamsDetailPage(recordId) {

                this.paramsDetailPageVisible = true;
                // 初始化数据
                this.initParamsDetail(recordId);
            },
            // 显示参数详情信息
            initParamsDetail(recordId) {

                let params = {

                    id: recordId
                };
                queryDeviceParamsDetailInfo(params).then((res) => {

                    // 渲染页面数据
                    let data = res.data;
                    this.paramForm = data;
                    // 启动锂电池充电直流母线电压值（下限） 需要除10
                    this.paramForm.startBatDcBusVMin = this.paramForm.startBatDcBusVMin / 10;
                    // 停止锂电池充电直流母线电压值（上限） 需要除10
                    this.paramForm.stopBatDcBusVMax = this.paramForm.stopBatDcBusVMax / 10;
                    // 锂电池充电限流点 需要除100
                    this.paramForm.batCle = this.paramForm.batCle / 100;
                    console.log(data);
                }).catch((err) => {

                    console.log(err);
                });
            },
            // 隐藏参数详情页面
            closeParamsDetailPage() {

                this.paramsDetailPageVisible = false;
            }
        }
    }
</script>

<style>

</style>
<style scoped="scoped">
</style>
